import { Input } from '@builder.io/sdk';
import { SettlementDetails } from '../components/PaymentMethod/interfaces';
import { ProjectStatus } from '../types';

export const S3_IMAGE_BASE_URL = 'https://launchgood.s3.amazonaws.com';
export const SUPPORT_LINK = 'https://support.launchgood.com/support/home';
export const TICKET_LINK = 'https://support.launchgood.com/support/tickets/new';
export const DROP_US_A_LINE = 'https://forms.gle/bm9LbnFR2yjtR8D28';

export const islamicMonthNames = [
  'Muharram',
  'Safar',
  'Rabi’ al-Awwal',
  'Rabi’ ath-Thani',
  'Jumādāl-ula',
  'Jumādā al-Thānī',
  'Rajab',
  'Sha’ban',
  'Ramadan',
  'Shawwal',
  "Dhul Qa'dah",
  'Dhul Hijjah',
];

export const monetaryAbbreviations = [
  { v: 1e3, s: 'K' },
  { v: 1e6, s: 'M' },
  { v: 1e9, s: 'B' },
  { v: 1e12, s: 'T' },
  { v: 1e15, s: 'P' },
  { v: 1e18, s: 'E' },
];

export const NO_CARD_FOUND = 'No Card Found';

export const signupInitialFormState = {
  firstName: {
    value: '',
    isValid: true,
    fieldBlurred: false,
    isDirty: false,
  },
  lastName: {
    value: '',
    isValid: true,
    fieldBlurred: false,
    isDirty: false,
  },
  email: {
    value: '',
    isValid: true,
    fieldBlurred: false,
    isDirty: false,
  },
  password: {
    value: '',
    isValid: true,
    fieldBlurred: false,
    isDirty: false,
  },
  confirmPassword: {
    value: '',
    isValid: true,
    fieldBlurred: false,
    isDirty: false,
  },
  terms: {
    value: false,
    isValid: true,
    fieldBlurred: false,
    isDirty: false,
  },
};
export const BuilderCampaignsCarouselInputs: Input[] = [
  {
    name: 'entity',
    friendlyName: 'Entity',
    type: 'string',
    enum: ['campaign', 'organization', 'community'],
    defaultValue: 'campaign',
  },
  {
    name: 'sort',
    friendlyName: 'Sort by',
    type: 'string',
    enum: ['', 'almost_funded', 'newest', 'needs_love', 'ending_soon'],
  },
  {
    name: 'zakatVerified',
    friendlyName: 'Zakat Verified',
    type: 'boolean',
  },
  {
    name: 'hideCommunityCampaigns',
    friendlyName: 'Hide Community Campaigns',
    type: 'boolean',
  },
  {
    name: 'nearMe',
    helperText:
      "This is automatically added if you're using the PopularNearYou component. Format: Latitude,Longitude,Radius. e.g. 40.7128,-74.0060,80.",
    friendlyName: 'Near Me',
    type: 'string',
  },
  {
    name: 'countries',
    friendlyName: 'Countries',
    helperText: 'ISO3166 2 letter country codes, e.g. US,CA,GB',
    type: 'string',
  },
  {
    name: 'charityType',
    friendlyName: 'Charity Type',
    type: 'string',
    enum: ['', '501c3', 'gift_aid', 'registered_charity'],
  },
  {
    name: 'categories',
    friendlyName: 'Categories',
    type: 'Tags',
    enum: [
      'food-water',
      'mosque-community',
      'education',
      'women',
      'orphans',
      'refugee',
      'health',
      'emergency-relief',
      'creative',
      'other',
    ],
  },
  {
    name: 'limit',
    friendlyName: 'Limit',
    type: 'number',
  },
  {
    name: 'offset',
    friendlyName: 'Offset',
    type: 'number',
  },
  {
    name: 'aggregateCategories',
    friendlyName: 'Aggregate Categories',
    type: 'boolean',
  },
  {
    name: 'canChooseInCampaignCreation',
    friendlyName: 'Can Choose In Campaign Creation',
    type: 'boolean',
  },
  {
    name: 'src',
    helperText: 'Just the src value, not the full "?src=xxx"',
    type: 'string',
  },
];

export const BuilderWizardCardInputs = [
  {
    name: 'bannerImg',
    friendlyName: 'Banner Image',
    type: 'file',
    allowedFileTypes: ['jpeg', 'jpg', 'png'],
    required: true,
  },
  {
    name: 'title',
    friendlyName: 'Title',
    type: 'string',
    required: true,
  },
  {
    name: 'desc',
    friendlyName: 'Description',
    type: 'richText',
    required: true,
  },
  {
    name: 'btnText',
    friendlyName: 'Button Text',
    type: 'string',
    required: true,
  },
  {
    name: 'postTitle',
    friendlyName: 'Post Action Title',
    type: 'string',
    required: true,
  },
  {
    name: 'postDesc',
    friendlyName: 'Description',
    type: 'richText',
    required: true,
  },
  {
    name: 'postBtnText',
    friendlyName: 'Post Action Button Text',
    type: 'string',
    required: false,
  },
  {
    name: 'postBtnLink',
    friendlyName: 'Post Action Button Link',
    type: 'string',
    required: false,
  },
  {
    name: 'dataTracking',
    friendlyName: 'Data Tracking',
    type: 'string',
    required: false,
  },
  {
    name: 'postDataTracking',
    friendlyName: 'Post Action Data Tracking',
    type: 'string',
    required: false,
  },
];

export enum HeapEvents {
  SUBSCRIPTION_RAMADAN_SELECT_PLAN = 'scheduled-giving:ramadan:subscription:plan-select',
  SUBSCRIPTION_RAMADAN_AMOUNT_SELECT = 'scheduled-giving:ramadan:subscription:amount-select',
  SUBSCRIPTION_RAMADAN_PAYMENT_CONFIRM = 'scheduled-giving:ramadan:subscription:payment-confim',
  SUBSCRIPTION_RAMADAN_COMPLETED = 'scheduled-giving:ramadan:subscription:completed',
  SUBSCRIPTION_DHC_AMOUNT_SELECT = 'scheduled-giving:dhc:subscription:amount-select',
  SUBSCRIPTION_DHC_LOGIN = 'scheduled-giving:dhc:subscription:login',
  SUBSCRIPTION_DHC_PAYMENT_CONFIRM = 'scheduled-giving:dhc:subscription:payment-confim',
  SUBSCRIPTION_DHC_COMPLETED = 'scheduled-giving:dhc:subscription:completed',
}

export const paymentMethodIcons = {
  google:
    'https://launchgood.s3.amazonaws.com/static/rebuild_assets/google-pay.svg',
  apple:
    'https://launchgood.s3.amazonaws.com/static/rebuild_assets/apple-pay.svg',
  addcard:
    'https://launchgood.s3.amazonaws.com/static/rebuild_assets/credit-card.svg',
  addach:
    'https://launchgood.s3.amazonaws.com/static/rebuild_assets/ach-payment-method.svg',
};

export const defaultUserAvatar =
  'https://launchgood.s3.amazonaws.com/static/rebuild_assets/avatar.svg';

export const qurbaniAssets =
  'https://launchgood.s3.amazonaws.com/static/rebuild_assets/qurbani';

export const EDT_Timezone = 'America/New_York';

export const contributionLeafImg =
  'https://launchgood.s3.amazonaws.com/static/rebuild_assets/contribution-leaf.svg';

export const RAMADAN_END_DATE = '2024-04-09T22:00:00';
export const EID_END_DATE = '2024-04-15T22:00:00';
export const RAMADAN_BOOST_END_DATE = '2024-05-01T00:00:00';
export const DHUL_HIJJAH_START_DATE = '2024-04-09T22:00:00';
export const DHUL_HIJJAH_1445_END_DATE = '2024-07-01T00:00:00';
export const randomLocations = [
  'Somewhere, Earth',
  'Allah’s Earth is vast',
  'A Kind City',
  'Somewhere in the world',
];

export const USStates: Record<string, string> = {
  AS: 'American Samoa',
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UM: 'United States Minor Outlying Islands',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands, U.S.',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const CanadianProvinces: Record<string, string> = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  YT: 'Yukon',
};

export const viewableStatuses: number[] = [
  ProjectStatus.ACTIVE,
  ProjectStatus.COMPLETED,
  ProjectStatus.COMPLETE_UNPAID,
  ProjectStatus.FAILED,
];

export const donateButtonText: Record<number, string> = {
  [ProjectStatus.NOT_SUBMITTED]: 'UNSUBMITTED',
  [ProjectStatus.PENDING_APPROVAL]: 'IN REVIEW',
  [ProjectStatus.SUSPENDED]: 'SUSPENDED',
  [ProjectStatus.ACTIVE]: 'DONATE',
  [ProjectStatus.COMPLETED]: 'SUCCESS!',
  [ProjectStatus.FAILED]: 'CLOSED',
  [ProjectStatus.APPROVED]: 'DONATE',
  [ProjectStatus.SUBMITTED_IN_REVIEW]: 'IN REVIEW',
  [ProjectStatus.DIED]: 'CLOSED',
  [ProjectStatus.REJECTED]: 'CLOSED',
  [ProjectStatus.COMPLETE_UNPAID]: 'SUCCESS!',
};

export const settlementDetails: SettlementDetails = {
  merchantId: 'LaunchGoodIncECOM',
  countryCode: 'US',
};

export const SUBSCRIPTION_PLANS_PAGE_SIZE = 5;
